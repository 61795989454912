import { defineComponent, computed, ref, reactive, watch } from '@vue/composition-api';
import { useAddNewVenueTagsMutation, useCreateNewTagMutation, useGetAllTagsQuery, useGetVenueTagsQuery, useGetSpaceTagsQuery } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { validationMixin } from 'vuelidate';
import { minValue, maxValue, numeric, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { ServerErrors } from '@/util/graphql';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
export default defineComponent({
    mixins: [validationMixin],
    validations: {
        newTagForm: {
            tagNameNl: {
                maxLength: maxLength(50),
                requiredIf: requiredIf(function () {
                    return this.newTagForm.tagNameEn.trim();
                })
            },
            tagNameEn: {
                maxLength: maxLength(50),
                requiredIf: requiredIf(function () {
                    return this.newTagForm.tagNameNl.trim();
                })
            },
            tagImportance: {
                numeric,
                maxValue: maxValue(10),
                minValue: minValue(1),
                requiredIf: requiredIf(function () {
                    return this.newTagForm.tagNameEn.trim();
                })
            }
        }
    },
    props: {
        city: {
            type: Object,
            required: true
        },
        reference: {
            type: String,
            required: true
        },
        slug: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { root } = context;
        const venueId = ref(0);
        const tags = ref([]);
        const selectedTagsIds = ref([]);
        const locale = computed(() => globalThis.$i18n.locale.toLowerCase());
        const newVenueTagModalOpened = ref(false);
        const newTagForm = ref({
            tagNameNl: '',
            tagNameEn: '',
            tagImportance: 1
        });
        const formErrors = ref({
            tagNameNl: '',
            tagNameEn: ''
        });
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const isAdmin = computed(() => {
            if (props.reference === 'space') {
                return false;
            }
            return user.value?.is_admin;
        });
        const { refetch: refetchVenueTags, onResult: onVenueTagsResult } = useGetVenueTagsQuery({
            slug: props.slug
        }, {
            enabled: props.reference === 'venue'
        });
        onVenueTagsResult((result) => {
            if (!result?.data?.specific_instance_by_slug)
                return;
            venueId.value = result.data?.specific_instance_by_slug?.venue_id || 0;
            tags.value =
                result.data?.specific_instance_by_slug?.venue_tags ||
                    [];
            selectedTagsIds.value = tags.value.map(tag => tag.tag_id);
        });
        const { onResult: onSpaceTagsResult } = useGetSpaceTagsQuery({
            slug: props.slug
        }, {
            enabled: props.reference === 'space'
        });
        onSpaceTagsResult((result) => {
            tags.value =
                result.data?.specific_instance_by_slug?.venue?.venue_tags ||
                    [];
        });
        const { mutate: createNewTagMutation } = useCreateNewTagMutation();
        const { mutate: addNewVenueTagsMutation } = useAddNewVenueTagsMutation();
        const allTagsQueryOptions = reactive({
            enabled: newVenueTagModalOpened.value
        });
        const { refetch: refetchAllTags, onResult } = useGetAllTagsQuery(allTagsQueryOptions);
        const allTagsLocalized = ref([]);
        /**
         * @description
         * This watcher is responsible for cleaning the newTagForm details when the modal is closed.
         */
        watch(() => newVenueTagModalOpened.value, (newValue, oldValue) => {
            if (newValue !== oldValue && !newValue) {
                newTagForm.value = {
                    tagNameNl: '',
                    tagNameEn: '',
                    tagImportance: 1
                };
                formErrors.value.tagNameEn = '';
                formErrors.value.tagNameNl = '';
            }
        }, { immediate: true });
        const handleTagClick = (tagID) => {
            if (selectedTagsIds.value.includes(tagID)) {
                selectedTagsIds.value.splice(selectedTagsIds.value.indexOf(tagID), 1);
            }
            else {
                selectedTagsIds.value.push(tagID);
            }
        };
        /**
         * @description
         * This function sends GTM event when the tag button is clicked.
         * @param tag
         */
        const onTagClick = (tag) => {
            globalThis.$gtm.trackEvent({
                event: 'tag_clicked',
                category: 'tags',
                action: 'click',
                label: 'Tag clicked',
                value: tag.nl,
                noninteraction: false
            });
        };
        const updateVenueTags = () => {
            addNewVenueTagsMutation({
                tagIds: selectedTagsIds.value,
                venueId: venueId.value
            })
                .then(() => {
                refetchVenueTags();
            })
                .catch(error => {
                console.error(error);
            });
        };
        /**
         * @description
         * This function triggers the GraphQL mutation to remove the selected tag from the venue.
         * Available only for admin users.
         * @param tag
         */
        const deleteVenueTag = (tag) => {
            selectedTagsIds.value.splice(selectedTagsIds.value.indexOf(tag.tag_id), 1);
            updateVenueTags();
        };
        /**
         * @description
         * On the click of the add tag icon, the available tags are requested from the server (see onResult).
         */
        const openNewVenueTagModal = () => {
            newVenueTagModalOpened.value = true;
            allTagsQueryOptions.enabled = true;
            refetchAllTags();
        };
        /**
         * @description
         * Watcher of the useGetAllTagsQuery.
         * If the results are still loading, do nothing.
         * When the results are loaded from the server, the tags are sorted alphabetically and the modal is opened.
         * Waiting for the server results is necessary to properly set the slots of the Select.vue in the modal.
         */
        onResult((result) => {
            if (result?.loading)
                return;
            allTagsLocalized.value = result.data?.get_all_tags
                .map(tag => {
                return {
                    ...tag,
                    value: tag.tag_id,
                    label: `${tag[locale.value]} (${tag.importance})`
                };
            })
                .sort((a, b) => a[locale.value].localeCompare(b[locale.value], globalThis.$i18n.locale)); // sorted alphabetically
        });
        /**
         * @description
         * This function triggers the GraphQL mutation to add a new tag to the venue.
         * Available only for admin users.
         * @param form
         */
        const addNewTag = (form) => {
            if (form.$invalid) {
                return;
            }
            const { tagNameNl, tagNameEn, tagImportance } = newTagForm.value;
            if (tagNameNl.length) {
                createNewTagMutation({
                    tagNameNl: tagNameNl,
                    tagNameEn: tagNameEn,
                    tagImportance: tagImportance
                })
                    .then(res => {
                    selectedTagsIds.value.push(res.data.add_new_tag[0].tag_id);
                    updateVenueTags();
                    newVenueTagModalOpened.value = false;
                    form.$reset();
                })
                    .catch(({ graphQLErrors }) => {
                    const graphQLError = ServerErrors.extractGQLError(graphQLErrors);
                    if (graphQLError && graphQLError.error?.key) {
                        const _error = root.$i18n
                            .t(`tag.errors.${graphQLError.error.key}`);
                        formErrors.value.tagNameEn = _error;
                        formErrors.value.tagNameNl = _error;
                    }
                });
            }
            else {
                updateVenueTags();
                newVenueTagModalOpened.value = false;
            }
        };
        return {
            allTagsLocalized,
            formErrors,
            isAdmin,
            locale,
            newTagForm,
            newVenueTagModalOpened,
            selectedTagsIds,
            tags,
            GeoPagesRoutes,
            addNewTag,
            deleteVenueTag,
            encodeURIComponent,
            handleTagClick,
            onTagClick,
            openNewVenueTagModal,
            useGetLocalizedPath
        };
    }
});
